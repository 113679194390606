const sortTableColumnWithoutAttributes = (arr, column, currentSort) => {
  return (
    Array.isArray(arr) &&
    arr?.sort((a, b) => {
      let modifier = 1;
      if (currentSort === 'desc') modifier = -1;
      if (a[column] < b[column]) return -1 * modifier;
      if (a[column] > b[column]) return 1 * modifier;
      return 0;
    })
  );
};

const sortTableColumnWithoutAttributesWithCount = (arr, column, currentSort) => {
  return (
    Array.isArray(arr) &&
    arr?.sort((a, b) => {
      let modifier = 1;
      if (currentSort === 'desc') modifier = -1;
      if (a[column].length < b[column].length) return -1 * modifier;
      if (a[column].length > b[column].length) return 1 * modifier;
      return 0;
    })
  );
};

const sortTableColumnWithAttributes = (arr, column, currentSort) => {
  return (
    Array.isArray(arr) &&
    arr?.sort(({ attributes: a }, { attributes: b }) => {
      let modifier = 1;
      if (currentSort === 'desc') modifier = -1;
      if (a[column] < b[column]) return -1 * modifier;
      if (a[column] > b[column]) return 1 * modifier;
      return 0;
    })
  );
};

const getRegionOrganisations = currentUser => {
  let regionOrganisations = currentUser?.data?.attributes?.employees?.filter(
    employee =>
      employee?.attributes?.organization_type === 'Region' &&
      employee?.attributes?.role &&
      !employee?.attributes?.soft_deleted
  );

  return regionOrganisations;
};

const isEmpty = arr => {
  return arr?.length === 0;
};

const isPresent = arr => {
  return Array.isArray(arr) && arr?.length > 0;
};

const hasMinimumTwo = arr => {
  return Array.isArray(arr) && arr?.length > 1;
};

const hasOneElement = arr => {
  return Array.isArray(arr) && arr?.length === 1;
};

const sortAlphabatically = arr => {
  return isPresent(arr) ? arr?.sort((a, b) => a.localeCompare(b)) : [];
};

const getRestaurantPreferenceSlotsTagsInputList = (slotsWithTags, restaurantSlotsCount, meal) => {
  return (
    isPresent(slotsWithTags) ? slotsWithTags : Array.from({ length: restaurantSlotsCount })
  ).map((slot, ind) => {
    return {
      name: `slot-${ind}`,
      selectedTags: slot?.tags
        ? slot?.tags?.map(name => ({ label: name, value: name }))
        : !meal?.new_client
        ? [{ label: 'All', value: 'ALL' }]
        : [],
    };
  });
};

export default {
  sortTableColumnWithoutAttributes,
  sortTableColumnWithoutAttributesWithCount,
  sortTableColumnWithAttributes,
  getRegionOrganisations,
  isEmpty,
  isPresent,
  hasMinimumTwo,
  sortAlphabatically,
  hasOneElement,
  getRestaurantPreferenceSlotsTagsInputList,
};
