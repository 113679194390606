import React, { useReducer, useContext, useEffect, useState } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCalendarCheck, faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { TimePicker, ToggleButton, RestaurantSlotsField } from 'components/Shared';
import { CustomDatePicker } from 'components/DatePicker';
import DatePicker from 'react-multi-date-picker';
import moment from 'moment';
import useEmployeeId from 'utils/helpers/user';
import dateTime from 'utils/helpers/dateTime';
import { alertHandler } from 'utils/middlewares/alertHandler';
import array from 'utils/helpers/array';
import validation from 'utils/helpers/validation';
import { RadioSelector } from 'components/Shared';
import object from 'utils/helpers/object';

function CreateMealSeriesFields({ closeModal, locationId, update, meal, timezone }) {
  const store = useContext(StoreContext);
  const { employeeId } = new useEmployeeId();
  const [datesToAddInput, setDatesToAddInput] = useState('');
  const [datesToSkipInput, setDatesToSkipInput] = useState('');
  const [autoDatesToAdd, setAutoDatesToAdd] = useState(true);
  const [autoDatesToSkip, setAutoDatesToSkip] = useState(true);

  const [days, setDaysOfWeek] = useState([
    { label: 'Mon', selected: true, value: 'monday' },
    { label: 'Tue', selected: true, value: 'tuesday' },
    { label: 'Wed', selected: true, value: 'wednesday' },
    { label: 'Thur', selected: true, value: 'thursday' },
    { label: 'Fri', selected: true, value: 'friday' },
    { label: 'Sat', selected: false, value: 'saturday' },
    { label: 'Sun', selected: false, value: 'sunday' },
  ]);

  const initialState = {
    targetTime: '',
    startDate: moment().toDate(),
    endDate: null, // for reoccuring meals we need to pass it as null.
    name: '',
    cutOff: '',
    subsidy: 0,
    datesToAdd: [],
    datesToSkip: [],
    expectedBags: 1,
    updateExpectedBagsAutomatic: false,
    expectedBagsMode: '1', //1 = last_five_orders & 2 = same_day_average
    parsedExpectedBags: null,

    //to update a meal series
    mealSeriesLocationId: 0,
    manualRestaurantSlots: false,
    restaurantSlots: '',

    //restaurant preferences
    slotsTagsInputList: [],
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (update && meal) {
      store.userStore.setLoader(true);
      store.mealManagementStore
        .getMealSeries(meal?.meal_series_id, { employee_id: employeeId })
        .then(response => {
          let data = response?.data?.attributes;

          store.userStore.setLoader(false);
          if (data) {
            dispatch({
              field: 'targetTime',
              value: data?.target_time,
            });
            dispatch({ field: 'mealSeriesLocationId', value: data?.location_id });
            dispatch({ field: 'name', value: data?.name });
            data?.start_date &&
              dispatch({ field: 'startDate', value: moment(data.start_date).toDate() });
            data?.end_date && dispatch({ field: 'endDate', value: moment(data.end_date).toDate() });
            dispatch({ field: 'cutOff', value: data?.cutoff });
            dispatch({ field: 'datesToAdd', value: data?.dates_to_add });
            dispatch({ field: 'datesToSkip', value: data?.dates_to_skip });
            dispatch({ field: 'subsidy', value: data?.subsidy });
            dispatch({ field: 'expectedBags', value: data?.expected_bags });
            dispatch({
              field: 'updateExpectedBagsAutomatic',
              value: data?.update_expected_bags_automatically,
            });
            setDaysOfWeek(
              days?.map(day => {
                if (data?.days.includes(day.value)) return { ...day, selected: true };
                else return { ...day, selected: false };
              })
            );
            dispatch({
              field: 'expectedBagsMode',
              value: data?.expected_bags_calculation_mode === 'same_day_average' ? '2' : '1',
            });
            dispatch({
              field: 'parsedExpectedBags',
              value:
                typeof data?.parsed_expected_bags === 'object' ? data?.parsed_expected_bags : null,
            });
          }
          dispatch({
            field: 'manualRestaurantSlots',
            value: data?.manual_restaurant_slots,
          });
          dispatch({
            field: 'restaurantSlots',
            value: data?.restaurant_slots,
          });

          dispatch({
            field: 'slotsTagsInputList',
            value: array.getRestaurantPreferenceSlotsTagsInputList(
              data?.slots,
              data?.restaurant_slots,
              meal
            ),
          });
        });
    }
  }, []);

  const submit = e => {
    e.preventDefault();

    let selectedDays = days?.filter(day => day.selected).map(day => day.value);
    let slotsWithTags = provider.slotsTagsInputList?.filter(slot =>
      array.isPresent(slot?.selectedTags)
    );

    if (!provider.startDate) {
      alertHandler({
        title: 'Please select the start date.',
      });
    } else if (!provider.cutOff || !provider.targetTime)
      alertHandler({
        title: 'Please select the order deadline and delivery time.',
      });
    else if (array.isEmpty(selectedDays) && array.isEmpty(provider.datesToAdd))
      alertHandler({
        title: 'Please select atleast one day of the week or specific days.',
      });
    else if (provider.manualRestaurantSlots && !provider.restaurantSlots)
      alertHandler({
        title: 'Please set the restaurant slots for manual calculations.',
      });
    else if (provider.restaurantSlots && provider.restaurantSlots !== slotsWithTags?.length) {
      alertHandler({
        title: 'Please set the tags for each slot.',
      });
    } else {
      store.userStore.setLoader(true);
      let payload = {
        location_id: update ? provider.mealSeriesLocationId : locationId,
        target_time: provider.targetTime,
        start_date: dateTime.formatDate(provider.startDate, 'YYYY-MM-DD'),
        end_date: dateTime.formatDate(provider.endDate, 'YYYY-MM-DD'),
        name: provider.name,
        cutoff: provider.cutOff,
        subsidy: provider.subsidy,
        dates_to_add: provider.datesToAdd,
        dates_to_skip: provider.datesToSkip,
        days: selectedDays,
        update_expected_bags_automatically: provider.updateExpectedBagsAutomatic,
        expected_bags: provider.expectedBags !== '' ? provider.expectedBags : 1,
        restaurant_slots: provider.restaurantSlots,
        manual_restaurant_slots: provider.manualRestaurantSlots,
        slots: provider.slotsTagsInputList?.map((slot, ind) => {
          return { slot_number: ind, tags: slot?.selectedTags?.map(tag => tag?.label) };
        }),
        expected_bags_calculation_mode:
          provider?.expectedBagsMode === '2' ? 'same_day_average' : 'last_five_orders',
      };

      update
        ? store.mealManagementStore
            .updateMealSeries(meal?.meal_series_id, { employee_id: employeeId }, payload)
            .then(response => {
              store.userStore.setLoader(false);
              store.mealManagementStore.getMealSeries(meal?.meal_series_id, {
                employee_id: employeeId,
              });
              if (response?.data?.id) closeModal();
            })
        : store.mealManagementStore
            .createMealSeries({ employee_id: employeeId }, payload)
            .then(response => {
              store.userStore.setLoader(false);
              if (response?.data?.id) closeModal();
            });
    }
  };

  const setDays = day => {
    let _days = days;
    setDaysOfWeek(
      _days.map(item => (item.label === day ? { ...item, selected: !item.selected } : item))
    );
  };

  const onChange = e => {
    const { value, name } = e.target;
    name === 'datesToAdd' ? setDatesToAddInput(value) : setDatesToSkipInput(value);
  };

  const isInvalid = type => {
    let helper = type === 'add' ? datesToAddInput : datesToSkipInput;
    return (
      helper
        .split(',')
        ?.map(date => {
          if (!validation.isValidDate(date)) return date;
        })
        ?.filter(date => date).length > 0
    );
  };

  const onKeyDown = e => {
    const { key } = e;

    const trimmedInput =
      e.target.name === 'datesToAdd' ? datesToAddInput.trim() : datesToSkipInput.trim();

    const isIncluded =
      e.target.name === 'datesToAdd'
        ? provider.datesToAdd.includes(trimmedInput)
        : provider.datesToSkip.includes(trimmedInput);

    if (key === 'Enter' && trimmedInput.length && !isIncluded) {
      e.preventDefault();

      let helper = [
        ...(e.target.name === 'datesToAdd' ? provider.datesToAdd : provider.datesToSkip),
        trimmedInput?.split(','),
      ];

      dispatch({
        field: e.target.name,
        value: helper.flat(),
      });
      e.target.name === 'datesToAdd' ? setDatesToAddInput('') : setDatesToSkipInput('');
    }
  };

  const deleteTag = (index, fieldName) => {
    let helper = fieldName === 'add' ? provider.datesToAdd : provider.datesToSkip;
    dispatch({
      field: fieldName === 'add' ? 'datesToAdd' : 'datesToSkip',
      value: helper?.filter((tag, i) => i !== index),
    });
  };

  const onFormKeyDown = e => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <div className="flex p-6 flex-col">
      <button
        onClick={() => {
          dispatch({ type: 'reset', payload: initialState });
          closeModal();
        }}>
        <FontAwesomeIcon
          icon={faTimes}
          color={'#ADB4C3'}
          size="lg"
          className="absolute top-4 right-4"
        />
      </button>

      <form
        className="rounded pt-4 w-full"
        onKeyDown={e => onFormKeyDown(e)}
        onSubmit={e => submit(e)}>
        <div className="w-full">
          <small className="input-label">Meal Name*</small>
          <input
            autoComplete="off"
            className="input-light"
            name="name"
            id="name"
            type="text"
            value={provider.name}
            onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
            required
          />
        </div>

        <div className="flex flex-col md:flex-row justify-between">
          <div className="w-full lg:w-auto">
            <small className="input-label">Start Date*</small>
            <CustomDatePicker
              setDate={date => {
                dispatch({ field: 'startDate', value: date });
              }}
              selectedDate={provider.startDate}
              maxDate={provider.endDate}
              transparent
              placeholder="Start Date"
            />
          </div>

          <div className="w-full lg:w-auto">
            <small className="input-label">End Date</small>
            <CustomDatePicker
              setDate={date => {
                dispatch({ field: 'endDate', value: date });
              }}
              selectedDate={provider.endDate}
              transparent
              placeholder="End Date"
              minDate={provider.startDate}
            />
          </div>
        </div>

        <div className="w-full d-col mt-4">
          <small className="input-label">Select days</small>
          <div className="d-row flex-wrap">
            {days.map(({ label, selected }) => {
              return (
                <button
                  type="button"
                  onClick={() => setDays(label)}
                  className={`${selected ? 'btn-purple' : 'btn-outline'} text-xs mr-2`}>
                  {label}
                </button>
              );
            })}
          </div>
        </div>

        <div className="d-row w-full items-end mt-2">
          {autoDatesToAdd ? (
            <div className="d-col w-3/4 ">
              <small className="input-label">Dates to Add</small>
              <DatePicker
                format="YYYY-MM-DD"
                onChange={dates =>
                  dispatch({
                    field: 'datesToAdd',
                    value: dates.map(date => moment(new Date(date)).format('YYYY-MM-DD')),
                  })
                }
                multiple
                minDate={provider.startDate}
                value={provider.datesToAdd}
                inputClass="input-light w-full"
              />
            </div>
          ) : (
            <div className="w-3/4">
              <div class="flex flex-row flex-wrap">
                {provider.datesToAdd.map((tag, index) => (
                  <div className="flex flex-row flex-wrap w-28 items-end">
                    <p className="input-label">{tag}</p>
                    <button
                      type="button"
                      className="text-dark-purple items-end mr-2 px-2 ml-1 hover:bg-light-gray"
                      onClick={() => deleteTag(index, 'add')}>
                      x
                    </button>
                  </div>
                ))}
              </div>

              <input
                autoComplete="off"
                value={datesToAddInput}
                name="datesToAdd"
                className="input-light"
                placeholder="2022-07-13,2022-09-17"
                // data-date-format="DD MMMM YYYY"
                onKeyDown={onKeyDown}
                autoFocus
                onChange={onChange}
              />
            </div>
          )}

          <button
            type="button"
            onClick={() => setAutoDatesToAdd(!autoDatesToAdd)}
            className="w-1/4 text-xs mx-2 mb-1">
            {autoDatesToAdd ? (
              <FontAwesomeIcon
                icon={faKeyboard}
                color={'#393686'}
                size="2x"
                className="hover:bg-purple-200"
              />
            ) : (
              <FontAwesomeIcon
                icon={faCalendarCheck}
                color={'#393686'}
                size="2x"
                className="hover:bg-purple-200"
              />
            )}
          </button>
        </div>

        {isInvalid('add') && (
          <small className="input-label text-red-500">Invalid date format</small>
        )}

        <div className="d-row w-full items-end mt-2">
          {autoDatesToSkip ? (
            <div className="d-col w-3/4">
              <small className="input-label">Dates to Skip</small>
              <DatePicker
                format="YYYY-MM-DD"
                onChange={dates =>
                  dispatch({
                    field: 'datesToSkip',
                    value: dates.map(date => moment(new Date(date)).format('YYYY-MM-DD')),
                  })
                }
                multiple
                minDate={provider.startDate}
                value={provider.datesToSkip}
                inputClass="input-light w-full"
              />
            </div>
          ) : (
            <div className="w-3/4 flex flex-col">
              <div class="flex flex-row flex-wrap">
                {provider.datesToSkip.map((tag, index) => (
                  <div className="flex flex-row flex-wrap w-28 items-end">
                    <p className="input-label">{tag}</p>
                    <button
                      type="button"
                      className="text-dark-purple items-end mr-2 px-2 ml-1 hover:bg-light-gray"
                      onClick={() => deleteTag(index, 'skip')}>
                      x
                    </button>
                  </div>
                ))}
              </div>

              <input
                autoComplete="off"
                value={datesToSkipInput}
                name="datesToSkip"
                className="input-light"
                placeholder="2022-07-13,2022-09-17"
                // data-date-format="DD MMMM YYYY"
                onKeyDown={onKeyDown}
                autoFocus
                onChange={onChange}
              />
            </div>
          )}

          <button
            type="button"
            onClick={() => setAutoDatesToSkip(!autoDatesToSkip)}
            className="w-1/4 text-xs mx-2 mb-1">
            {autoDatesToSkip ? (
              <FontAwesomeIcon
                icon={faKeyboard}
                color={'#393686'}
                size="2x"
                className="hover:bg-purple-200"
              />
            ) : (
              <FontAwesomeIcon
                icon={faCalendarCheck}
                color={'#393686'}
                size="2x"
                className="hover:bg-purple-200"
              />
            )}
          </button>
        </div>

        {isInvalid('skip') && (
          <small className="input-label text-red-500">Invalid date format</small>
        )}

        <div className="flex flex-col md:flex-row justify-between mt-2">
          <div className="flex flex-row">
            <div className="d-col px-0 pl-1">
              <small className="input-label">
                Order Deadline* {dateTime.checkAndGetTimezone(timezone)}
              </small>

              <TimePicker
                onChange={time => dispatch({ field: 'cutOff', value: time })}
                value={provider.cutOff}
              />
            </div>
          </div>

          <div className="d-col">
            <small className="input-label">
              Delivery Time* {dateTime.checkAndGetTimezone(timezone)}
            </small>

            <TimePicker
              onChange={time => dispatch({ field: 'targetTime', value: time })}
              value={provider.targetTime}
            />
          </div>
        </div>

        {/* <div className="w-full">
          <small className="input-label">Expected Bags</small>
          <input
autoComplete="off"
            className="input-light"
            name="accountNumber"
            id="accountNumber"
            value={provider.accountNumber}
            type="number"
            onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
            required
          />
        </div> */}
        {/* <div className="w-full">
          <small className="input-label">Eligible Teams/Users</small>
          <Select isMulti className="input-light border-none w-full p-0" options={options} />
        </div> */}

        <div className="w-full">
          <small className="input-label">Meal Subsidy</small>
          <input
            autoComplete="off"
            className="input-light"
            name="subsidy"
            id="subsidy"
            value={provider.subsidy}
            type="number"
            onWheelCapture={e => {
              e.target.blur();
            }}
            onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
            required
          />
        </div>

        <div className="w-full">
          <small className="input-label">Eligible teams & users</small>
          <input autoComplete="off" className="input-light" type="text" disabled />
        </div>

        <div className="w-full d-row items-center my-1.5 ">
          <small className="input-label mr-3">Update Expected Bags Automatically</small>

          <ToggleButton
            toggleState={provider.updateExpectedBagsAutomatic}
            setToggleState={value => {
              dispatch({ field: 'updateExpectedBagsAutomatic', value: value });
              if (!value) {
                dispatch({ field: 'expectedBagsMode', value: '1' });
              }
            }}
          />
        </div>

        {provider.updateExpectedBagsAutomatic && (
          <div>
            <RadioSelector
              firstLabel="Last 5 Orders"
              secondLabel="Same-Day Average"
              selectorValue={provider.expectedBagsMode}
              setSelector={value => {
                dispatch({ field: 'expectedBagsMode', value: value });
              }}
            />
          </div>
        )}

        <div className="w-full my-1.5">
          {provider.expectedBagsMode === '1' ? (
            <>
              <small className="input-label">Expected Orders*</small>
              <input
                autoComplete="off"
                className="input-light"
                name="expectedBags"
                disabled={provider.updateExpectedBagsAutomatic}
                id="expectedBags"
                value={provider.expectedBags}
                onChange={e => {
                  if (e.target.value > -1)
                    dispatch({ field: e.target.name, value: e.target.value });
                }}
                type="number"
                onWheelCapture={e => {
                  e.target.blur();
                }}
                onKeyPress={validation.preventMinus}
              />
            </>
          ) : (
            <div className="mt-2">
              {object.isPresent(provider.parsedExpectedBags) && (
                <>
                  <small className="input-label">Expected Orders*</small>
                  {Object.entries(provider.parsedExpectedBags).map(key => (
                    <small key={key} className="input-label d-col capitalize text-dark-blue my-1.5">
                      {key[0]}: {key[1]}
                    </small>
                  ))}
                </>
              )}
            </div>
          )}
        </div>

        {(array.isPresent(provider.slotsTagsInputList) || !update) && (
          <RestaurantSlotsField dispatch={dispatch} provider={provider} />
        )}

        {/* <div className="flex-wrap d-row mt-2">
          <div className="w-full d-row">
            <strong className="input-label font-inter-semibold">Bill with Company? </strong>
            <label className="inline-flex items-center ml-3">
              <input
autoComplete="off"
                type="checkbox"
                className="form-checkbox"
                onClick={e => setBillCompany(e.target.checked)}
                checked={billCompany}
              />
            </label>
          </div>
        </div> */}
        {/* {!billCompany && (
          <>
            <div className="w-full">
              <small className="input-label">Billing Name</small>
              <input
autoComplete="off"
                className="input-light"
                name="accountNumber"
                id="accountNumber"
                value={provider.accountNumber}
                type="text"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>
            <div className="w-full">
              <small className="input-label">Billing Email</small>
              <input
autoComplete="off"
                className="input-light"
                name="accountNumber"
                id="accountNumber"
                value={provider.accountNumber}
                type="text"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>
            <div className="d-col">
              <small className="input-label">Payment Terms</small>
              <Dropdown label="Select" />
            </div>
            <div className="d-col mt-2">
              <small className="input-label">Payment Methods</small>
              <Dropdown label="Select" />
            </div>
          </>
        )} */}

        {/* <div className="d-col my-3">
          <strong className="font-inter-semibold text-sm">Tip Settings</strong>

          <div className="w-full">
            <small className="input-label">Tip Alias</small>
            <input
autoComplete="off"
              className="input-light"
              name="accountNumber"
              id="accountNumber"
              // value={provider.accountNumber}
              type="number"
              // onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
            />
          </div>

          <label className="flex items-center my-2">
            <input
autoComplete="off"
              checked={tipDisplayToUser}
              onClick={e => setTipDisplayUser(e.target.checked)}
              type="checkbox"
              className="form-checkbox"
            />
            <span className="ml-1 text-xs">Display to User</span>
          </label>

          {tipDisplayToUser && (
            <div className="w-full mt-3">
              <div className="d-row">
                <small className="input-label">Options</small>
                <label className="flex items-center ml-4">
                  <input
autoComplete="off"
                    // checked={tipOption}
                    // onClick={e => setTipOptions(e.target.checked)}
                    type="checkbox"
                    className="form-checkbox"
                  />
                  <span className="ml-1 text-xs">Included in subsidy</span>
                </label>
              </div>
              <input
autoComplete="off"
                className="input-light"
                name="accountNumber"
                id="accountNumber"
                // value={provider.accountNumber}
                type="number"
                // onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
              />
            </div>
          )}

          <div className="w-full">
            <small className="input-label">Force on client</small>
            <input
autoComplete="off"
              className="input-light"
              name="accountNumber"
              id="accountNumber"
              // value={provider.accountNumber}
              type="number"
              // onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
            />
          </div>
        </div> */}

        {/* <strong className="font-inter-semibold text-sm">Service Fee</strong> */}

        {/* <div className="w-full">
          <small className="input-label">Service Fee Alias</small>
          <input
autoComplete="off"
            className="input-light"
            name="accountNumber"
            id="accountNumber"
            // value={provider.accountNumber}
            type="text"
            // onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
            required
          />
        </div> */}
        {/* <div className="w-full">
          <small className="input-label">Rate</small>
          <input
autoComplete="off"
            className="input-light"
            name="accountNumber"
            id="accountNumber"
            value={provider.accountNumber}
            type="text"
            onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
            required
          />
        </div> */}
        {/* <div className="d-col mt-1">
          <small className="input-label">Rate Base</small>
          <Dropdown label="Select" />
        </div> */}

        {/* <label className="flex items-center my-2">
          <input
autoComplete="off"
            checked={serviceDisplayToUser}
            onClick={e => setServiceDisplayUser(e.target.checked)}
            type="checkbox"
            className="form-checkbox"
          />
          <span className="ml-1 text-xs">Display to User</span>
        </label> */}

        {/* {serviceDisplayToUser && (
          <label className="flex items-center mt-2 ml-3">
            <input
autoComplete="off"
              checked={serviceField}
              onClick={e => setServiceField(e.target.checked)}
              type="checkbox"
              className="form-checkbox"
            />
            <span className="ml-1 text-xs">Included in subsidy</span>
          </label>
        )} */}
        {/* 
        <label className="flex items-center mt-2">
          <input
autoComplete="off"
            // checked={serviceField}
            // onClick={e => setServiceField(e.target.checked)}
            type="checkbox"
            className="form-checkbox"
          />
          <span className="ml-1 text-xs">Force on Client</span>
        </label> */}

        {/* <div className="my-3">
          <strong className="font-inter-semibold text-sm">Delivery Fee</strong>
          <div className="w-full">
            <small className="input-label">Delivery Alias</small>
            <input
autoComplete="off"
              className="input-light"
              name="accountNumber"
              id="accountNumber"
              // value={provider.accountNumber}
              type="text"
              // onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
              required
            />
          </div>
          <div className="w-full">
            <small className="input-label">Rate</small>
            <input
autoComplete="off"
              className="input-light"
              name="accountNumber"
              id="accountNumber"
              // value={provider.accountNumber}
              type="text"
              // onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
              required
            />
          </div>
          <div className="d-col mt-1">
            <small className="input-label">Rate Base</small>
            <Dropdown label="Select" />
          </div>
          <label className="flex items-center my-2">
            <input
autoComplete="off"
              checked={deliveryDisplayToUser}
              onClick={e => setDeliveryDisplayUser(e.target.checked)}
              type="checkbox"
              className="form-checkbox"
            />
            <span className="ml-1 text-xs">Display to User</span>
          </label>

          {deliveryDisplayToUser && (
            <label className="flex items-center mt-2 ml-3">
              <input
autoComplete="off"
                // checked={serviceField}
                // onClick={e => setServiceField(e.target.checked)}
                type="checkbox"
                className="form-checkbox"
              />
              <span className="ml-1 text-xs">Included in subsidy</span>
            </label>
          )}

          <label className="flex items-center mt-2">
            <input
autoComplete="off"
              // checked={serviceField}
              // onClick={e => setServiceField(e.target.checked)}
              type="checkbox"
              className="form-checkbox"
            />
            <span className="ml-1 text-xs">Force on Client</span>
          </label>
        </div> */}

        {/* <div className="d-col my-4">
          <strong className="font-inter-semibold text-sm">Billable Option</strong>

          <div className="d-col mt-1">
            <div className="d-col">
              <label className="flex items-center mt-2">
                <input
autoComplete="off"
                  checked={billableText}
                  onClick={e => setBillableTextField(e.target.checked)}
                  type="checkbox"
                  className="form-checkbox"
                />
                <span className="ml-2 text-xs">Text Fields</span>
              </label>

              {billableText && (
                <>
                  <div className="w-full">
                    <small className="input-label">Field Name</small>
                    <input
autoComplete="off"
                      className="input-light"
                      name="accountNumber"
                      id="accountNumber"
                      value={provider.accountNumber}
                      type="text"
                      onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                      required
                    />
                  </div>

                  <label className="flex items-center mt-2">
                    <input
autoComplete="off"
                      // checked={billableDropdown}
                      // onClick={e => setBillableDropdown(e.target.checked)}
                      type="checkbox"
                      className="form-checkbox"
                    />
                    <span className="ml-2 text-xs">Required</span>
                  </label>
                  <div className="w-full h-0.5 my-4 bg-gray-300" />
                </>
              )}
            </div>

            <div className="d-col">
              <label className="flex items-center mt-2">
                <input
autoComplete="off"
                  checked={billableDropdown}
                  onClick={e => setBillableDropdown(e.target.checked)}
                  type="checkbox"
                  className="form-checkbox"
                />
                <span className="ml-2 text-xs">Dropdowns</span>
              </label>
              {billableDropdown && (
                <>
                  <div className="w-full">
                    <small className="input-label">Field Name</small>
                    <input
autoComplete="off"
                      className="input-light"
                      name="accountNumber"
                      id="accountNumber"
                      value={provider.accountNumber}
                      type="text"
                      onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                      required
                    />
                  </div>

                  <div className="w-full">
                    <small className="input-label">Options for Dropdown</small>
                    <input
autoComplete="off"
                      className="input-light"
                      name="accountNumber"
                      id="accountNumber"
                      placeholder="Comma Separated"
                      value={provider.accountNumber}
                      type="text"
                      onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                      required
                    />
                  </div>

                  <label className="flex items-center mt-2">
                    <input
autoComplete="off"
                      // checked={tipOption}
                      // onClick={e => setTipOptions(e.target.checked)}
                      type="checkbox"
                      className="form-checkbox"
                    />
                    <span className="ml-2 text-xs">Bill by checkout option</span>
                  </label>

                  <label className="flex items-center mt-2">
                    <input
autoComplete="off"
                      // checked={billableDropdown}
                      // onClick={e => setBillableDropdown(e.target.checked)}
                      type="checkbox"
                      className="form-checkbox"
                    />
                    <span className="ml-2 text-xs">Required</span>
                  </label>

                  <div className="w-full h-0.5 my-4 bg-gray-300" />
                </>
              )}
            </div>

            <label className="flex items-center mt-2">
              <input
autoComplete="off"
                // checked={tipOption}
                // onClick={e => setTipOptions(e.target.checked)}
                type="checkbox"
                className="form-checkbox"
              />
              <span className="ml-2 text-xs">Bill By Team</span>
            </label>
          </div>
        </div> */}

        <div className="items-center flex flex-col pt-2 md:pt-20">
          <button className="btn-purple w-full text-sm" type="submit">
            {update ? 'Update' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateMealSeriesFields;
