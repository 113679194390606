import { ToggleButton } from 'components/Shared';
import React, { useContext, useEffect } from 'react';
import { Dropdown } from 'components/Shared';
import useEmployeeId from 'utils/helpers/user';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import array from 'utils/helpers/array';

function RestaurantSlotsField({ provider, dispatch }) {
  const store = useContext(StoreContext);
  const mealManagementStore = toJS(store.mealManagementStore);
  const { employeeId } = new useEmployeeId();
  const { uncateringTags } = mealManagementStore;

  let { slotsTagsInputList, restaurantSlots, manualRestaurantSlots } = provider;

  useEffect(() => {
    const updatedInputList = [...(slotsTagsInputList || [])];

    if (restaurantSlots > updatedInputList.length) {
      for (let i = updatedInputList.length; i < restaurantSlots; i++) {
        updatedInputList.push({
          name: `slot-${i}`,
          selectedTags: [],
        });
      }
    } else if (restaurantSlots < updatedInputList.length) {
      updatedInputList.splice(restaurantSlots);
    }

    dispatch({ field: 'slotsTagsInputList', value: updatedInputList });
  }, [restaurantSlots]);

  const getTags = () => {
    if (array.isEmpty(uncateringTags)) {
      store.userStore.setLoader(true);
      store.mealManagementStore
        .getUncateringTags({ employee_id: employeeId })
        .then(() => store.userStore.setLoader(false));
    }
  };

  const handleInputChange = (option, slot) => {
    let slots = slotsTagsInputList;
    slots = slots?.map(slotInfo => {
      if (slotInfo?.name === slot?.name) {
        slotInfo.selectedTags = option;
        return slotInfo;
      }
      return slotInfo;
    });

    dispatch({ field: 'slotsTagsInputList', value: slots });
  };

  return (
    <>
      <div className="w-full d-col my-1.5">
        <small className="input-label my-1">Manual restaurant slots calculation</small>

        <ToggleButton
          toggleState={manualRestaurantSlots}
          setToggleState={value => dispatch({ field: 'manualRestaurantSlots', value: value })}
        />
      </div>

      <div className="w-full mt-2">
        <small className="input-label">Restaurant Slots</small>

        <div class="custom-number-input w-32">
          <div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
            <button
              disabled={!manualRestaurantSlots}
              onClick={() => {
                if (restaurantSlots > 0)
                  dispatch({
                    field: 'restaurantSlots',
                    value: Number(restaurantSlots) - 1,
                  });
              }}
              data-action="decrement"
              type="button"
              class="bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none">
              <span class="m-auto text-2xl font-thin">−</span>
            </button>

            <input
              type="number"
              disabled
              class="outline-none focus:outline-none text-center w-full font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700"
              name="custom-input-number"
              value={restaurantSlots || 0}
            />

            <button
              disabled={!manualRestaurantSlots}
              onClick={() => {
                dispatch({ field: 'restaurantSlots', value: Number(restaurantSlots) + 1 });
              }}
              data-action="increment"
              type="button"
              class="bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer">
              <span class="m-auto text-2xl font-thin">+</span>
            </button>
          </div>
        </div>
      </div>

      {slotsTagsInputList?.map((slot, i) => {
        return (
          <div className="d-col animate-fade-in-up mt-2">
            <small className="input-label">Slot {i + 1}</small>

            <div className="w-full shadow">
              <Dropdown
                options={uncateringTags?.map(name => ({ label: name, value: name }))}
                isMulti
                lowerCase
                label="Select Tags"
                onFocus={getTags}
                selected={slot?.selectedTags}
                onChange={option => handleInputChange(option, slot)}
              />
            </div>
          </div>
        );
      })}
    </>
  );
}

export default RestaurantSlotsField;
