import moment from 'moment';
const priceFormatter = number => {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(number);
};

const toDecimal = price => {
  return price !== '' ? Number(price).toFixed(2) : price;
};

const validatePassword = password => {
  return /^(?=.*\d)(?=.*[a-zA-Z]).{8,100}$/.test(password);
};

const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const generatePassword = () => {
  const length = 8;
  let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const generateDestinationCode = () => {
  const length = 5;
  let charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const isValidDate = value => {
  let format = 'YYYY-MM-DD';
  return moment(value, format, true).isValid(); // true
};

const generateId = () => {
  return Math.floor(Math.random() * 10000) + 10000000;
};

const preventMinus = e => {
  if (e.code === 'Minus') {
    e.preventDefault();
  }
};

const preventLeadingSpaces = str => {
  const trimmedString = str?.trimStart(); // Remove leading and trailing spaces

  return trimmedString;
};

const preventMinusAndDecimal = e => {
  const allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  if (!allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export default {
  priceFormatter,
  validatePassword,
  validateEmail,
  toDecimal,
  generatePassword,
  generateDestinationCode,
  isValidDate,
  generateId,
  preventMinus,
  preventLeadingSpaces,
  preventMinusAndDecimal,
};
