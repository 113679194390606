export default {
  login: () => `/v1/sessions`,
  restaurants: () => `/v1/staff/restaurants`,
  profile: (id, employeeId) => `/v1/profiles/${id}?employee_id=${employeeId}`, //to get & update the logged in user profile
  forgotPassword: () => `/v1/passwords/new`,
  resetPassword: () => `/v1/passwords`,
  markets: () => `/v1/staff/markets`,
  zones: () => `/v1/staff/zones`,
  bankingInformation: restaurantId => `/v1/staff/restaurant/banking_information/${restaurantId}`,
  accountingEmails: restaurantId => `/v1/staff/restaurant/accounting_emails/${restaurantId}`,
  salesReport: () => `/v1/staff/restaurant/reports`,
  restaurantProfile: restaurantId => `/v1/staff/restaurant/profiles/${restaurantId}`,
  menuFilters: () => `/v1/staff/restaurant/filters`,
  schedules: () => `/v1/staff/restaurant/schedules`,
  allSchedules: () => `/v1/staff/schedules`,
  menus: () => `/v1/staff/restaurant/menus`,
  optionCategories: itemId => `/v1/staff/restaurant/menu_items/${itemId}`,
  tags: () => `/v2/staff/restaurant/tags`,
  restaurantShipments: () => `/v1/staff/restaurant/shipments`,
  restaurantOrders: () => `/v1/staff/restaurant/orders`,
  clients: () => `/v1/staff/locations`,
  zoneLocations: () => `/v1/staff/zone_locations`,
  meals: () => `/v1/staff/meals`,
  locationRestaurants: () => `/v2/staff/location_menus`,
  mealSeries: () => `/v1/staff/meal_series`,
  meal: () => `/v1/staff/meals`,
  eta: () => `/v1/staff/restaurant/estimated_arrivals`,
  employees: () => `/v1/staff/restaurant/employees`,
  officeEmployees: () => `/v1/staff/company/employees`,
  menuCategories: () => `/v1/staff/restaurant/menu_categories`,
  menuItems: () => `/v1/staff/restaurant/menu_items`,
  badges: () => `/v1/staff/restaurant/badges`,
  preSignedUrl: () => `/v1/presigned_urls`,
  optionGroups: () => `/v1/staff/restaurant/option_groups`,
  options: () => `/v1/staff/restaurant/options`,
  associatedAccounts: userId => `/v1/staff/associated_accounts/${userId}`,
  reports: () => `/v1/staff/reports`,

  staff: () => `/v1/staff/employees`,
  staffShipments: () => `/v1/staff/shipments`,
  teams: () => `/v1/staff/company/teams`,
  officeEmployee: () => `/v1/staff/company/employees`,
  officeUserCredit: userId => `/v1/staff/company/adjustments`,
  officeUserOrder: () => `/v1/staff/user_orders`,
  cateringOrderDetails: id => `/v1/staff/restaurant/orders/${id}`,
  updateCateringOrder: id => `/v2/staff/restaurant/orders/${id}`,

  //user management
  users: () => `/v1/staff/users`,
  userDetail: userId => `/v1/staff/users/${userId}`,
  creditCards: () => `/v1/staff/company/credit_cards`,
  companyMenus: () => `/v1/staff/company/menus`,
  menuOptions: () => `/v1/staff/company/option_groups`,
  userOrders: () => `/v1/staff/user_orders`,
  orderReceives: id => `/v1/staff/order_receives/${id}/send_email`,

  //account management
  orders: () => `/v1/staff/company/orders`,
  companyEmployees: () => `/v1/staff/company/employees`,
  companyETA: () => `/v1/staff/company/estimated_arrivals`,
  officeTeams: () => `/v1/staff/company/teams`,
  employeeTeams: () => `/v1/staff/company/employee_teams`,
  teamAssignments: () => `/v1/staff/company/team_assignments`,
  companyProfile: () => `/v1/staff/company/profiles`,
  locationMenus: () => `/v1/staff/company/location_menus`,
  fetchUser: email => `/v1/profiles/${email}`,
  accountBillings: () => `/v1/staff/account_management_billings`,
  invoiceReport: () => `/v1/staff/company/invoices`,
  dropoffRequirement: () => `v1/staff/dropoff_requirements`,
  officeGroupOrders: () => `v2/staff/company/group_orders`,

  //placeOrder
  orderMenus: userId => `/v1/staff/company/employees/${userId}/menus`,
  mealMenu: mealId => `/v1/staff/company/menus/${mealId}`,
  operatingHoursAvailable: id => `/v1/staff/operating_hours/${id}`,
  placeOrder: () => `/v2/staff/company/employees`,

  //market management

  //delivery management
  deliveryLocations: () => `/v1/staff/locations`,
  onFleetTask: () => `/v1/staff/tasks`,
  ODCuncateringOrders: () => `/v1/staff/orders`,
  notifyUsers: () => `/v1/staff/user_notifications`,
  shipmentTask: () => `/v1/staff/shipment_tasks`,
  deliveryTimes: () => `/v1/staff/delivery_times`,
  associatedMenus: () => `/v1/staff/restaurant/associated_menus`,

  //restaurantManagement: accounting
  paymentBatch: () => `/v1/staff/batch_payments`,
  batchBills: batchId => `v1/staff/batch_payments/${batchId}/reports`,

  //restaurant management
  menuOverwriteOrders: () => `/v1/staff/orders`,

  //client score
  clientRank: () => `v1/staff/client_ranks`,

  //catering orders
  cateringOrders: () => `/v2/staff/orders`,
  groupOrders: () => `/v2/staff/group_orders`,
};
