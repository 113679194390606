export const availabilities = [
  { label: 'Active', value: 'active' },
  { label: 'Sold Out', value: 'sold_out' },
  { label: 'In Active', value: 'in_active' },
  { label: 'Removed', value: 'removed' },
  { label: 'Deleted', value: 'deleted' },
];

export const customerSupportAvailabilities = [
  { label: 'Active', value: 'active' },
  { label: 'Sold Out', value: 'sold_out' },
  { label: 'In Active', value: 'in_active' },
];

export const specialUncateringTags = ['ALL', 'H1', 'H2', 'C1', 'C2'];
